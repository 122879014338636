#auth{
    display: flex;
    height: 100vh;
    width: 100%;
}

.left{
    width: calc(100% - 450px);
    justify-content: left;
    background: rgb(45,86,169);
background: linear-gradient(90deg, rgba(45,86,169,1) 0%, rgba(9,9,121,1) 100%, rgba(45,91,169,1) 100%);
    height: 100%;
}

.left-cont{
    height: 100vh;
}


.left-center{
    position: relative;
    z-index: 2;
    top: 20%;
    padding-left: 10%;
    transform: translateY(-50%);
    width: 100%;
}

.left h1{
    color: white;
    text-shadow: 0 0 8px rgba(37, 37, 37, 0.507);
    font-weight: 600;
    margin-top: 10px;
    font-size: 2.3rem;
}

.left h6{
    text-shadow: 0 0 8px rgba(37, 37, 37, 0.507);
    color: white;
    font-weight: bolder;
    margin-top: 10px;
    font-size: 2.6rem;
}


.left img{
    width: 80px;
    margin: 20px;
}

.left p{
    color: #bbbbbb;
    font-size: 0.9rem;
}


.left-bottom{
    position: absolute;
    bottom: 10px;
    left: 8%;
}

.left-bottom img{
    width: 60%;
}

.right{
    width: 450px;
    justify-content: left;
    height: 100%;
}


.right h4{
    font-weight: 600;
    font-size: 1.8rem;
}

.right-cont{
    position: relative;
    padding:6%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.mb-logo{
    display: none;
    text-align: center;
}

.mb-logo img{
    height:120px !important;
    width: 180px !important;
}

.right-cont img{
    width: 150px;
    height: 100px;
}

.utype-r-mb{
    display: flex;
    margin-top: 15px;
}

.utype-r-mb label{
    width: 125px !important;
}

.utype-r-mb span{
    position: relative;
    bottom: 4px;
}

.utype-r-mb input{
    width: 20px !important;
    margin-right: 5px;
    height: 20px !important;
}

/* .utype-r{
    position: fixed;
    bottom: 10px;
    left: 50px;
} */

.utype-r span{
    color: #fff;
}

.utype-r label{
    padding: 0px 15px;
    color: #fff;
}

.utype-r input{
    margin-right: 3px;
}

.auth-form-cont{
}


.auth-form-cont label, .auth-form-cont input{
    width: 100%;
    height: 45px;
}

.auth-form-cont input{
    padding: 5px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
}

.auth-form-cont input::placeholder{
    font-size: .9rem;
}

.auth-form-cont label{
    font-weight: 600;
    margin-left: 10px;
}


.auth-btn{
    margin-top: 15px;
    border: none !important;
    color: white;
    width: 100%;
    height: 45px;
    transition: 0.2s ease-in-out;
    background-color: #2d55a8;
}

.auth-btn:hover{
    background-color: #1a3770;
}

.auth-form-cont .otp-box{
    border: 1px solid black;
    border-radius: 8px;
    text-align: center;
}

.otp-col{
    padding: 7px;
}

@media screen and (max-width: 1345px){
    .left-bottom{
        position: absolute;
        bottom: 10px;
        left: 2%;
    }
    
    .left-bottom img{
        width: 55%;
    }
}

@media screen and (max-width: 1139px){
    .left-bottom{
        display: none;
    }


    .left-cont{
        text-align: center;
    }

    .left img{
        width: 170px;
        position: relative;
        z-index: 2;
        top: 40%;
        padding-left: 5%;
        transform: translateY(-50%);
    }


    .left-center{
        position: relative;
        z-index: 2;
        top: 40%;
        padding-left: 0%;
        transform: translateY(-50%);
        width: 100%;
    }
}

@media screen and (max-width: 850px){
    .left{
        width: 0;
        display: none;
    }

    .right{
        width: 100%;
    }

    .right-cont{
        padding: 40px;
    }

    .mb-logo{
        display: block;
    }
}

@media screen and (max-width: 600px){

    .right-cont{
        padding: 15px;
    }
}