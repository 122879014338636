

.panel{
    background: #E6EEFE
}


.panel h4{
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    width: 100%;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #163470;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:nth-of-type(odd) {
    background-color: #cbddff;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #163470;
}


.styled-table tbody td:first-child{
    font-weight: 600;
}


.panel table tr td button{
    border: none;
    outline: none;
    color: #fff;
    width: 80%;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 500;
    background: rgb(1, 201, 1);
}


/* Call History */

.call-card{
    width: 100%;
    display: flex;
    margin: 10px 0;
    padding: 5px;
    border-radius: 10px;
    background-color: #fff;
    color: #212121
}


.page-item:last-child .page-link{
    z-index: 1;
}