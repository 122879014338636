@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');


*{
    margin: 0;
    padding: 0;
    font-family: 'Questrial', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #6d6d6d;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0f47e0;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0432b1;
  }


  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}