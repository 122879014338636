#admin_panel{
    width: 100%;
    min-height: 100vh;
    display: flex;
}


.header-bar{
    width: 100%;
    height: 65px;
    position: fixed;
    top:0;
    z-index: 2;
    background-color: #fff;
    box-shadow:  0 0 5px rgba(0,0,0,0.1);
    text-align: right;
}

.next-table-header{
    background-color: #2d55a8 !important;
    color: white;
  }

.table{
    /* --bs-table-bg: #2d55a8  */
    /* --bs-table-bg: #2d55a8 !important;
    --bs-table-color: white;
    --bs-table-striped-color: white;
    --bs-table-hover-color: white;
    --bs-table-striped-bg:#fff */
    --bs-table-bg: none;
    --bs-table-color: none;
}

.header-right{
    width: 100%; 
}

.profile-photo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #163470;
}

#side_bar h1{
    font-size: 1.4rem;
    padding-top: 20px;
    padding-left: 10px;
    font-weight: 600;
    color:#fff;
}


#side_bar{
    width: 280px;
    text-overflow: hidden;
    transition: .5s ease-in-out;
    justify-content: left;
    background-color: #163470;
    height: 100vh;
    position: fixed;
    overflow: auto;
    z-index: 2;
}



#change-p-i{ 
    width: 100%;
    display: none;
    padding: 0 10px;
}

#change-p-i input{
    width: 100%;
    margin-bottom: 15px;
    height: 45px;
    padding: 5px;
}

.C7PnSkxqD6bOXhZlRlcc {
    top: auto !important;
    bottom: 5px;
    right: 5px !important;
    width: 202px !important;
    height: 132px !important;
}

.R5UUhzsZdRHH4U5V6kon{
    bottom: auto !important;
    top: 10px;
    right: auto !important;
    left: 10px;
}

.side_bar_header{
    height: 65px;
    background-color: #163470;
}


.side_bar_content .list.active, .side_bar_content .list:hover{
    background: #fff;
}




.side_bar_content .list{
    color: #163470;
    cursor: pointer;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-right: 15px;
    height: 45px;
    overflow: hidden;
    background-color: #9ca5b6;
    margin-bottom: 15px;
}

.side_bar_content .list h5{
    font-size: 1.1rem;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    text-overflow: hidden;
    top: 11px;
    left: 15px;
}

/* panel */
.panel_bar{
    width: calc(100% - 280px);
    justify-content: right;
    position: absolute;
    right: 0;
}

.panel{
    padding: 85px 25px;
    min-height: 100vh;
}

.panel h2{
    
}


.panelHead .p-head-icon{
    display: none;
}


.panel_cont input[type=radio]{
    height: 20px;
    width: 20px;    
}


.page-item:first-child .page-link{
    z-index: 1;
}

.zego_model_parent{
    min-width: 0 !important;
}


@media screen and (max-width: 800px) {
    #side_bar{
        width: 0;
        position: absolute;
        min-height: 100vh;
        z-index: 3;
    }

    .panel{
        padding: 85px 15px;
        width: 100%;
    }
    
    .panel_cont{
        padding: 14px;
    }

    .panel_bar{
        width: 100% !important;
    }

    .panelHead{
        background-color: #2b273e;
        padding: 14px;
        color: white;
        display: flex;
        width: 100%;
        flex: 1
    }

    .panelHead .p-head-icon{
        font-size: 1.9rem;
        display: block;
        margin-right: 15px;
        position: relative;
        top: 1.5px;
    }


   

    .panelHead h4{
        font-weight: 600;
        position: relative;
        top: 4px;
    }

    .profile_icon{
        background-color: transparent;
    }

    
}